import styled from "styled-components";
import { Box } from "reflexbox";

const InfoBanner = styled(Box)`
  border-radius: 15px;
  background-color: white;
  box-shadow: 0px 4px 20px rgba(0, 66, 62, 0.08);
`;

export default InfoBanner;
