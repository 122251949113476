import React from "react";
import app from "../../components/Firebase";
import { withRouter, Redirect } from "react-router";

const Home = () => {
  return (
    <>
      <Redirect to={"/client/" + app.auth().currentUser.uid} />
    </>
  );
};

export default withRouter(Home);
