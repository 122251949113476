import React from "react";
import { Helmet } from "react-helmet";

const GlobalHead = () => (
  <Helmet>
    <meta charset="UTF-8" />
    <meta
      name="description"
      content="Websites inclusief SEO, advertenties, e-mail en meer. Uw concurrentie voorbij door een op maat gemaakt online totaalpakket."
    />
    <meta name="subject" content="upshift | Klantenportaal" />
    <title>upshift | Klantenportaal</title>
    <meta name="keywords" content="upshift, klantenportaal" />
    <meta name="author" content="upshift" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <meta name="robots" content="index, follow" />
    <meta name="googlebot" content="index,follow" />
    <meta property="og:url" content="https://app.upshift.be" />
    <meta property="og:type" content="website" />
    <meta property="og:title" content="upshift" />
    <meta property="og:description" content="upshift | Klantenportaal" />
    <meta property="og:locale" content="nl_BE" />
    <meta property="article:author" content="upshift" />
    <meta name="twitter:card" content="summary" />
    <meta name="twitter:url" content="https://app.upshift.be" />
    <meta name="twitter:title" content="upshift" />
    <meta name="twitter:description" content="upshift | Klantenportaal" />
    <html lang="nl" />

    <link
      href="https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;600;700&display=swap"
      rel="stylesheet"
    />
  </Helmet>
);

export default GlobalHead;
