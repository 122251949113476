import React from "react";
import app from "../../components/Firebase";
import { Flex, Box } from "reflexbox";
import GlobalCollection from "../../config/GlobalCollection";
import DomainProducts from "./DomainProducts";
import Hero from "../../components/Hero";

const Domains = () => {
  const [project, setProject] = React.useState();
  const [domains, setDomains] = React.useState([]);

  React.useEffect(() => {
    const fetchProject = async () => {
      const db = app.firestore();
      const clientsRef = db.collection("projects");
      const data = await clientsRef
        .where("clients", "array-contains", app.auth().currentUser.email)
        .limit(1)
        .get();

      if (data.docs[0]) {
        if (data.docs[0].data().name) {
          const projectName = data.docs[0].data().name;
          setProject(projectName);
          fetchDomains(projectName);
        } else {
          setProject("Project gevonden, maar nog geen naam gedefinieerd");
        }
      } else {
        setProject("Project kon niet worden teruggevonden.");
      }
    };

    const fetchDomains = async (prj) => {
      const db = app.firestore();
      const domainsRef = db.collection("domains");
      const data = await domainsRef.where("project", "==", prj).get();
      console.info(data);
      setDomains(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };

    fetchProject();
  }, []);

  return (
    <>
      <GlobalCollection>
        <Hero>
          <>
            <h1>Domeinen</h1>
            <p>
              Hier vind je al je domeinen terug die gelinkt zijn aan je website.
            </p>
            <p>
              Een domein is wat je klanten intypen in hun browser om naar je
              website te surfen.
            </p>
          </>
        </Hero>
        <Flex flexWrap="wrap">
          <Box width={[1, 1 / 2]} p={3}>
            <h2>In bezit</h2>
            {console.log("project" + project)}
            {domains.length > 0 ? (
              domains.map((domain, index) => (
                <div key={index}>
                  {domain.list.map((url, index) => (
                    <p key={index}>{url}</p>
                  ))}
                </div>
              ))
            ) : (
              <p>Geen domeinen gevonden.</p>
            )}
          </Box>
          <Box width={[1, 1 / 2]} p={3}>
            <DomainProducts />
          </Box>
        </Flex>
      </GlobalCollection>
    </>
  );
};

export default Domains;
