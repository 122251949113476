import React from "react";
import styled from "styled-components";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <FooterWrapper>
      <p>
        {`\u00A9`} upshift {currentYear}
      </p>
    </FooterWrapper>
  );
};

export default Footer;

const FooterWrapper = styled.div`
  max-width: 1200px;
  margin: 250px auto 0 auto;
`;
