import React from "react";
import app from "../../components/Firebase";
import { Flex, Box } from "reflexbox";
import GlobalCollection from "../../config/GlobalCollection";
import styled from "styled-components";
import LiveSvg from "../../assets/images/live.svg";
import TestSvg from "../../assets/images/test.svg";
import BlogSvg from "../../assets/images/blog.svg";
import Hero from "../../components/Hero";
import InfoBanner from "../../components/InfoBanner";

const Websites = () => {
  const [website, setWebsite] = React.useState([]);

  React.useEffect(() => {
    const fetchProject = async () => {
      const db = app.firestore();
      const clientsRef = db.collection("projects");
      const data = await clientsRef
        .where("clients", "array-contains", app.auth().currentUser.email)
        .limit(1)
        .get();

      if (data.docs[0]) {
        if (data.docs[0].data().name) {
          const projectName = data.docs[0].data().name;
          fetchWebsite(projectName);
        } else {
          console.log("Project gevonden, maar nog geen naam gedefinieerd");
        }
      } else {
        console.log("Project kon niet worden teruggevonden.");
      }
    };

    const fetchWebsite = async (prj) => {
      const db = app.firestore();
      const websiteRef = db.collection("websites");
      const data = await websiteRef.where("project", "==", prj).get();
      console.log(data);
      setWebsite(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };

    fetchProject();
  }, []);

  return (
    <>
      <GlobalCollection>
        <Box p={3}>
          <Hero>
            <>
              <h1>Website</h1>
              <p>
                Hier vind je informatie terug over je website en verschillende
                links, afhankelijk van wat je hebt aangevraagd.
              </p>
            </>
          </Hero>
        </Box>
        <Flex flexWrap="wrap">
          <Box width={[1, 1 / 2]} p={3}>
            {website[0] && website[0].live ? (
              <Website
                href={website[0].live}
                target="_blank"
                rel="noreferrer noopener"
              >
                <WebsiteImage src={LiveSvg} alt="live" />
                <Flex flexDirection="column">
                  <p>Je live website</p>
                  <p>({website[0].live})</p>
                </Flex>
              </Website>
            ) : (
              <Website>
                <WebsiteImage src={LiveSvg} alt="live" />
                Nog geen live website gedefinieerd
              </Website>
            )}
          </Box>
          <Box width={[1, 1 / 2]} p={3}>
            {website[0] && website[0].test ? (
              <Website
                href={website[0].test}
                target="_blank"
                rel="noreferrer noopener"
              >
                <WebsiteImage src={TestSvg} alt="live" />
                <Flex flexDirection="column">
                  <p>Je test omgeving</p>
                  <p>({website[0].test})</p>
                </Flex>
              </Website>
            ) : (
              <Website>
                <WebsiteImage src={TestSvg} alt="live" />
                Nog geen test omgeving gedefineerd
              </Website>
            )}
          </Box>
        </Flex>
        <Flex flexWrap="wrap">
          <Box width={[1, 1 / 2]} p={3}>
            {website[0] && website[0].blog ? (
              <Website
                href={website[0].blog}
                target="_blank"
                rel="noreferrer noopener"
              >
                <WebsiteImage src={BlogSvg} alt="live" />
                <Flex flexDirection="column">
                  <p>Je blog</p>
                  <p>({website[0].blog})</p>
                </Flex>
              </Website>
            ) : (
              <Website>
                <WebsiteImage src={BlogSvg} alt="live" />
                Nog geen blog gedefinieerd
              </Website>
            )}
          </Box>
        </Flex>
        <InfoBanner p={3}>
          <p>Onze websites bevatten standaard:</p>
          <ul>
            <li>Een beveiligde verbinding (SSL)</li>
            <li>Supersnelle laadtijden</li>
            <li>Hosting vanaf fysiek dichtst bijzijnde land</li>
          </ul>
        </InfoBanner>
      </GlobalCollection>
    </>
  );
};

export default Websites;

const Website = styled.a`
  padding: 25px;
  background-color: white;
  box-shadow: 0px 4px 20px rgba(0, 66, 62, 0.08);
  border-radius: 40px 10px;
  color: black;
  display: flex;
  align-items: center;
`;

const WebsiteImage = styled.img`
  margin-right: 25px;
  width: 50px;
`;
