import React from "react";
import app from "../../components/Firebase";
import { Flex, Box } from "reflexbox";
import GlobalCollection from "../../config/GlobalCollection";
import Hero from "../../components/Hero";
import InfoBanner from "../../components/InfoBanner";
import styled from "styled-components";

const Email = () => {
  const [emailProducts, setEmailProducts] = React.useState(["Laden..."]);
  const [emails, setEmails] = React.useState([]);

  React.useEffect(() => {
    const fetchEmailsByProject = async () => {
      const db = app.firestore();
      const clientsRef = db.collection("projects");
      const data = await clientsRef
        .where("clients", "array-contains", app.auth().currentUser.email)
        .limit(1)
        .get();

      if (data.docs[0]) {
        if (data.docs[0].data().name) {
          const projectName = data.docs[0].data().name;
          fetchEmails(projectName);
        } else {
          console.log("Project gevonden, maar nog geen naam gedefinieerd");
        }
      } else {
        console.log("Project kon niet worden teruggevonden.");
      }
    };

    const fetchEmails = async (prj) => {
      const db = app.firestore();
      const emailsRef = db.collection("emails");
      const data = await emailsRef.where("project", "==", prj).get();
      console.info(data);
      setEmails(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };

    const fetchEmailProducts = async () => {
      const db = app.firestore();
      const data = await db.collection("emailProducts").get();
      setEmailProducts(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
      console.log(data.docs[0]);
    };

    fetchEmailsByProject();
    fetchEmailProducts();
  }, []);

  return (
    <>
      <GlobalCollection>
        <Hero>
          <>
            <h1>E-mails</h1>
            <p>
              Hier vind je alle e-mail adressen terug die wij voor jou beheren.
            </p>
          </>
        </Hero>
        <Flex flexWrap="wrap">
          <Box p={3} width={[1, 1 / 2]}>
            <StyledBox p={4}>
              <h2>E-mails</h2>
              {emails[0] && emails[0].list ? (
                <ul>
                  {emails[0].list.map((email, index) => (
                    <li>{email}</li>
                  ))}
                </ul>
              ) : (
                <p>Geen e-mails gevonden.</p>
              )}
            </StyledBox>
          </Box>
          <Box p={3} width={[1, 1 / 2]}>
            <StyledBox p={4}>
              <h2>E-mail pakketten</h2>
              {emailProducts.map((emailProduct, index) => (
                <>
                  <h3>{emailProduct.type}</h3>
                  <p>Opslag (Google Drive): {emailProduct.storage}</p>
                  <p>Prijs: €{emailProduct.cost} per maand</p>
                </>
              ))}
            </StyledBox>
          </Box>
        </Flex>
        <InfoBanner p={3} mt={5}>
          <p>Voor e-mails zijn er altijd onbeperkt gratis aliassen mogelijk.</p>
          <p>Aliassen zijn 'synoniemen' voor een bepaald e-mail adres.</p>
          <p>
            Als u bijvoorbeeld naam@domein.be heeft, kan u gratis
            contact@domein.be of info@domein.be toevoegen.
          </p>
        </InfoBanner>
      </GlobalCollection>
    </>
  );
};

export default Email;

const StyledBox = styled(Box)`
  background-color: white;
  box-shadow: 0px 4px 20px rgba(0, 66, 62, 0.08);
  border-radius: 40px 10px;
`;
