import React from "react";
import "./App.css";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { AuthProvider } from "./context/Auth";
import PrivateRoute from "./routes/PrivateRoute";

import Client from "./modules/client/Client";
import Domains from "./modules/domains/Domains";
import Emails from "./modules/emails/Emails";
import Licenses from "./modules/licenses/Licenses";
import Login from "./modules/login/Login";
import Overview from "./modules/overview/Overview";
import SignUp from "./modules/signup/SignUp";
import Statistics from "./modules/statistics/Statistics";
import Tools from "./modules/tools";
import Website from "./modules/website/Website";

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <div>
          <PrivateRoute exact path="/" component={Overview} />
          <PrivateRoute path="/client/:uid" component={Client} />

          <PrivateRoute path="/domains" component={Domains} />
          <PrivateRoute path="/emails" component={Emails} />
          <PrivateRoute path="/licenses" component={Licenses} />
          <PrivateRoute path="/statistics" component={Statistics} />
          <PrivateRoute path="/tools" component={Tools} />
          <PrivateRoute path="/website" component={Website} />

          <Route exact path="/login" component={Login} />
          <Route exact path="/signup" component={SignUp} />
        </div>
      </Router>
    </AuthProvider>
  );
};

export default App;
