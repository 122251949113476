import React, { useCallback, useContext } from "react";
import { withRouter, Redirect } from "react-router";
import { Link } from "react-router-dom";
import app, { provider } from "../../components/Firebase.js";
import { AuthContext } from "../../context/Auth.js";
import styled from "styled-components";
import GlobalHead from "../../config/GlobalHead";
import GlobalStyle from "../../config/GlobalStyle";
import Google from "../../assets/images/google.png";
import Logo from "../../assets/images/upshift-logo.png";

const Login = ({ history }) => {
  const handleLoginWithUsernameAndPassword = useCallback(
    async (event) => {
      event.preventDefault();
      const { email, password } = event.target.elements;
      try {
        await app
          .auth()
          .signInWithEmailAndPassword(email.value, password.value);
        history.push("/");
      } catch (error) {
        alert(error);
      }
    },
    [history]
  );

  const handleLoginWithGoogle = useCallback(async () => {
    try {
      await app.auth().signInWithPopup(provider);
      history.push("/");
    } catch (error) {
      alert(error);
    }
  }, [history]);

  const { currentUser } = useContext(AuthContext);

  if (currentUser) {
    return <Redirect to={"/client/" + app.auth().currentUser.uid} />;
  }

  return (
    <>
      <GlobalHead />
      <GlobalStyle />
      <LoginWrapper>
        <StyledLogo />
        <h1>Log in</h1>
        <GoogleLoginButton onClick={handleLoginWithGoogle}>
          <GoogleImg src={Google} />
          Log in via Google
        </GoogleLoginButton>
        <StyledForm onSubmit={handleLoginWithUsernameAndPassword}>
          <StyledLabel>
            E-mail
            <StyledInput name="email" type="email" placeholder="E-mail" />
          </StyledLabel>
          <StyledLabel>
            Wachtwoord
            <StyledInput
              name="password"
              type="password"
              placeholder="Wachtwoord"
            />
          </StyledLabel>
          <LoginButton type="submit">Log in</LoginButton>
          <p>
            Nog geen account? Maak er <StyledLink to="/signup">hier</StyledLink>{" "}
            een aan.
          </p>
          <p>
            Of gebruik de knop bovenaan om rechtstreeks met uw Google account in
            te loggen.
          </p>
        </StyledForm>
      </LoginWrapper>
    </>
  );
};

export default withRouter(Login);

const GoogleImg = styled.img`
  height: 20px;
  padding-right: 10px;
  vertical-align: bottom;
`;

const LoginWrapper = styled.div`
  margin: 25px auto;
  max-width: 500px;
  text-align: center;
  font-family: "Inter", sans-serif;
`;

const GoogleLoginButton = styled.button`
  font-family: inherit;
  background-color: white;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid lightgrey;
  margin-top: 25px;
`;

const LoginButton = styled.button`
  font-family: inherit;
  background-color: white;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid lightgrey;
  margin-bottom: 25px;
`;

const StyledLabel = styled.label`
  display: block;
  margin: 20px;
`;

const StyledInput = styled.input`
  display: block;
  margin: 10px auto;
  padding: 5px 10px;
  border-radius: 5px;
  font-family: inherit;
  border: 1px solid lightgrey;
`;

const StyledForm = styled.form`
  border-top: 1px solid lightgrey;
  margin: 25px;
`;

const StyledLogo = styled.div`
  width: 50px;
  margin-left: 25px;
  background-color: red;
  height: 50px;
  padding: 15px;
  background: #ffffff url(${Logo}) no-repeat center center;
  background-size: contain;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);
  border-radius: 20px 8px;
  margin-bottom: 15px;
`;

const StyledLink = styled(Link)`
  text-decoration: underline;
`;
