import React from "react";
import app from "../../components/Firebase";
import styled from "styled-components";

const DomainProducts = () => {
  const [products, setProducts] = React.useState([]);

  React.useEffect(() => {
    const fetchDomainProducts = async () => {
      const db = app.firestore();
      const data = await db.collection("domainProducts").get();
      setProducts(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };

    fetchDomainProducts();
  }, []);

  return (
    <>
      <h2>Aanbod</h2>
      {products.length > 0 ? (
        products.map((product, index) => (
          <DomainProduct>
            <h3 key={index + "type"}>{product.type}</h3>
            <p key={index + "cost"}> Prijs: €{product.cost} per maand</p>
            <p key={index + "info"}> {product.info}</p>
          </DomainProduct>
        ))
      ) : (
        <p>Laden...</p>
      )}
    </>
  );
};

export default DomainProducts;

const DomainProduct = styled.div`
  margin-bottom: 10px;
`;
