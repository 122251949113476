import React from "react";
import app from "../../components/Firebase";
import { Flex, Box } from "reflexbox";
import GlobalCollection from "../../config/GlobalCollection";
import Hero from "../../components/Hero";
import InfoBanner from "../../components/InfoBanner";
import styled from "styled-components";

const Email = () => {
  const [licensesOffice2019, setLicensesOffice2019] = React.useState([]);

  React.useEffect(() => {
    const fetchLicensesOffice2019ByProject = async () => {
      const db = app.firestore();
      const clientsRef = db.collection("projects");
      const data = await clientsRef
        .where("clients", "array-contains", app.auth().currentUser.email)
        .limit(1)
        .get();

      if (data.docs[0]) {
        if (data.docs[0].data().name) {
          const projectName = data.docs[0].data().name;
          fetchLicensesOffice2019(projectName);
        } else {
          console.log("Project gevonden, maar nog geen naam gedefinieerd");
        }
      } else {
        console.log("Project kon niet worden teruggevonden.");
      }
    };

    const fetchLicensesOffice2019 = async (prj) => {
      const db = app.firestore();
      const licensesOffice2019Ref = db.collection("licensesOffice2019");
      const data = await licensesOffice2019Ref
        .where("project", "==", prj)
        .get();
      console.info(data);
      setLicensesOffice2019(
        data.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
      );
    };

    fetchLicensesOffice2019ByProject();
  }, []);

  return (
    <>
      <GlobalCollection>
        <Hero>
          <>
            <h1>Licenties</h1>
            <p>
              Hier vind je alle licenties voor producten aangekocht via upshift.
            </p>
          </>
        </Hero>
        <Flex flexWrap="wrap">
          <Box p={3} width={[1, 1 / 2]}>
            <StyledBox p={4}>
              <h2>Microsoft Office 2019</h2>
              {licensesOffice2019[0] && licensesOffice2019[0].list ? (
                <ul>
                  {licensesOffice2019[0].list.map((license, index) => (
                    <li>{license}</li>
                  ))}
                </ul>
              ) : (
                <p>Geen licenties gevonden.</p>
              )}
            </StyledBox>
          </Box>
        </Flex>
        <InfoBanner p={3} mt={5}>
          <p>Hieronder vindt u de links om uw product te installeren.</p>
          <p>
            Gebruik uw activatie code tijdens de installatie van uw software.
          </p>
          <h3>Microsoft Office 2019</h3>
          <Box my={1}>
            <Styleda
              href="https://officecdn.microsoft.com/pr/492350f6-3a01-4f97-b9c0-c7c6ddf67d60/media/nl-nl/ProPlus2019Retail.img"
              target="_blank"
            >
              Office Professional Plus 2019 (Nederlands)
            </Styleda>
          </Box>
          <Box my={1}>
            <Styleda
              href="https://officecdn.microsoft.com/pr/492350f6-3a01-4f97-b9c0-c7c6ddf67d60/media/en-us/ProPlus2019Retail.img"
              target="_blank"
            >
              Office Professional Plus 2019 (Engels)
            </Styleda>
          </Box>
        </InfoBanner>
      </GlobalCollection>
    </>
  );
};

export default Email;

const StyledBox = styled(Box)`
  background-color: white;
  box-shadow: 0px 4px 20px rgba(0, 66, 62, 0.08);
  border-radius: 40px 10px;
`;

const Styleda = styled.a`
  text-decoration: underline;
`;
