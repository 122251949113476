import React from "react";
import app from "../../components/Firebase";
import GlobalCollection from "../../config/GlobalCollection";

const Statistics = () => {
  const [company, setCompany] = React.useState("");
  const [statistics, setStatistics] = React.useState([]);

  React.useEffect(() => {
    const fetchStatistics = async (cmp) => {
      const db = app.firestore();
      const domainsRef = db.collection("statistics");
      const data = await domainsRef.where("company", "==", cmp).limit(1).get();
      if (data.docs[0].data() !== undefined) {
        setStatistics(data.docs[0].data());
      } else {
        setStatistics();
      }
    };

    const fetchDataByCompany = async () => {
      const db = app.firestore();
      const clientsRef = db.collection("companies");
      const data = await clientsRef
        .where("emails", "array-contains", app.auth().currentUser.email)
        .limit(1)
        .get();
      const companyName = data.docs[0].data().name;
      if (companyName !== undefined) {
        setCompany(data.docs[0].data().name);
        fetchStatistics(companyName);
      } else {
        setCompany("Bedrijf kon niet gevonden worden.");
      }
    };
    fetchDataByCompany();
  }, []);

  return (
    <>
      <GlobalCollection>
        <h1>Statistieken</h1>
        <h2>Bedrijf</h2>
        {company ? <p>{company}</p> : <p>Laden...</p>}
        <h2>Prestaties in Google</h2>
        {statistics.searchConsole ? (
          <a
            href={statistics.searchConsole}
            target="_blank"
            rel="noopener noreferrer"
          >
            Overzicht
          </a>
        ) : (
          <p>Laden...</p>
        )}
      </GlobalCollection>
    </>
  );
};

export default Statistics;
