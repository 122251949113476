import React from "react";
import styled from "styled-components";
import DashboardSvg from "../assets/images/dashboard.svg";
import { Flex, Box } from "reflexbox";

const Hero = ({ children }) => (
  <HeroWrapper>
    <Flex alignItems="center" flexWrap="wrap-reverse">
      <Box width={[1, 3 / 4]}>{children}</Box>
      <Box width={[1, 1 / 4]}>
        <StyledDashboardSvg src={DashboardSvg} alt="dashboard" />
      </Box>
    </Flex>
  </HeroWrapper>
);

export default Hero;

const HeroWrapper = styled.div`
  background-color: white;
  box-shadow: 0px 4px 20px rgba(0, 66, 62, 0.08);
  border-radius: 40px 10px;
  padding: 25px 25px 10px 25px;
  margin-bottom: 50px;
`;

const StyledDashboardSvg = styled.img`
  width: 150px;
  vertical-align: center;
  margin: -50px 25px 0 25px;
  ${"" /* @media screen and (max-width: 640px) {
    display: none;
  } */}
`;
