import React, { useCallback } from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import app from "../../components/Firebase";
import styled from "styled-components";
import GlobalHead from "../../config/GlobalHead";
import GlobalStyle from "../../config/GlobalStyle";
import Logo from "../../assets/images/upshift-logo.png";

const SignUp = ({ history }) => {
  const handleSignUp = useCallback(
    async (event) => {
      event.preventDefault();
      const { email, password } = event.target.elements;
      try {
        await app
          .auth()
          .createUserWithEmailAndPassword(email.value, password.value);
        history.push("/");
      } catch (error) {
        alert(error);
      }
    },
    [history]
  );

  return (
    <>
      <GlobalHead />
      <GlobalStyle />
      <SignUpWrapper>
        <StyledLogo />
        <Styleda href="/login">← Terug naar login</Styleda>
        <h1>Gratis registreren</h1>
        <SignUpForm onSubmit={handleSignUp}>
          <StyledLabel>
            E-mail
            <StyledInput name="email" type="email" placeholder="Email" />
          </StyledLabel>
          <StyledLabel>
            Wachtwoord
            <StyledInput
              name="password"
              type="password"
              placeholder="Password"
            />
          </StyledLabel>
          <SignUpButton type="submit">Registreren</SignUpButton>
        </SignUpForm>
        <p>
          Indien u een Google account heeft, moet u geen aparte account maken.
        </p>
        <p>
          U kan <Link to="/login">hier</Link> rechtstreeks inloggen.
        </p>
      </SignUpWrapper>
    </>
  );
};

export default withRouter(SignUp);

const SignUpWrapper = styled.div`
  max-width: 500px;
  margin: 0 auto 25px auto;
  text-align: center;
  font-family: "Inter", sans-serif;
  padding: 25px;
`;

const SignUpButton = styled.button`
  font-family: inherit;
  background-color: white;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid lightgrey;
`;

const StyledLabel = styled.label`
  display: block;
  margin: 20px;
`;

const SignUpForm = styled.form`
  margin-bottom: 25px;
`;

const StyledInput = styled.input`
  display: block;
  margin: 10px auto;
  padding: 5px 10px;
  border-radius: 5px;
  font-family: inherit;
  border: 1px solid lightgrey;
`;

const Styleda = styled.a`
  text-align: left;
  display: block;
  margin-bottom: 25px;
`;

const StyledLogo = styled.div`
  width: 50px;
  background-color: red;
  height: 50px;
  padding: 15px;
  background: #ffffff url(${Logo}) no-repeat center center;
  background-size: contain;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);
  border-radius: 20px 8px;
  margin-bottom: 15px;
`;
