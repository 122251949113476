import React from "react";
import GlobalCollection from "../../config/GlobalCollection";
const Tools = () => {
  return (
    <>
      <GlobalCollection>
        <h1>Tools</h1>
      </GlobalCollection>
    </>
  );
};

export default Tools;
