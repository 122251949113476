import * as React from "react";
import GlobalCollection from "../../config/GlobalCollection";
import app, { db } from "../../components/Firebase";
import Hero from "../../components/Hero";
import styled from "styled-components";

const Client = (props) => {
  console.dir(props.match.params);

  const name = app.auth().currentUser.displayName;
  const email = app.auth().currentUser.email;
  const uid = app.auth().currentUser.uid;
  const currentUid = props.match.params.uid;

  // let previousCompanies = [];

  const [clientInfo, setClientInfo] = React.useState({});
  // const [newCompany, setNewCompany] = React.useState("");
  // const [isAdmin, setIsAdmin] = React.useState(false);
  // const [searchUid, setSearchUid] = React.useState("");
  // const [emailSearchString, setEmailSearchString] = React.useState("");
  // const []

  // const handleAddCompany = () => {
  //   if (clientInfo.companies && clientInfo.companies.length > 0) {
  //     previousCompanies = clientInfo.companies;
  //   }
  //   const newCompanies = previousCompanies.concat(newCompany);
  //   setClientInfo({ ...clientInfo, companies: newCompanies });
  //   console.dir(clientInfo);
  //   setNewCompany("");
  // };

  // const enterPressed = (event) => {
  //   var code = event.keyCode || event.which;
  //   if (code === 13) {
  //     //13 is the enter keycode
  //     handleAddCompany();
  //   }
  // };

  // const handleDeleteCompany = (index) => {
  //   if (clientInfo.companies && clientInfo.companies.length > 0) {
  //     previousCompanies = clientInfo.companies;
  //   }
  //   const newCompanies = previousCompanies;
  //   newCompanies.splice(index, 1);
  //   setClientInfo({ ...clientInfo, companies: newCompanies });
  // };

  // const handleNewCompany = (newCompanyFromInput) => {
  //   setNewCompany(newCompanyFromInput);
  // };

  // const handleUpdateEmailSearchString = (searchInput) => {
  //   setEmailSearchString(searchInput);
  //   console.log(emailSearchString);
  // };

  // React.useEffect(() => {
  //   console.log(emailSearchString && emailSearchString.length > 2);
  //   const getClientInfoByMail = async () => {
  //     const clientsRef = db.collection("clients");
  //     const data = await clientsRef
  //       .where("email", "==", emailSearchString)
  //       .limit(1)
  //       .get();

  //     if (data.docs[0]) {
  //       console.dir(data.docs[0].id);
  //       setSearchUid(data.docs[0].id);
  //     }
  //   };
  //   if (emailSearchString && emailSearchString.length > 2) {
  //     console.log("searching by mail");
  //     getClientInfoByMail();
  //   }
  // }, [emailSearchString]);

  React.useEffect(() => {
    const getIsAdmin = async () => {
      console.log("checking if admin for " + uid);
      const clientsRef = db.collection("clients");
      await clientsRef
        .doc(uid)
        .get()
        .then((doc) => {
          if (doc.exists && doc.data().admin) {
            console.log("admin data:");
            console.dir(doc.data().admin);
            // setIsAdmin(doc.data().admin);
          }
        });
    };
    getIsAdmin();
  }, [uid]);

  React.useEffect(() => {
    const getOrSetClientInfo = async () => {
      const clientsRef = db.collection("clients");
      console.log(currentUid + "just before triggering");
      await clientsRef
        .doc(currentUid)
        .get()
        .then((doc) => {
          if (doc.exists) {
            console.log("Found client data from db.");
            const data = doc.data();
            setClientInfo(data);
          } else {
            if (currentUid === uid) {
              setInitialClientInfoIfEmpty(name, email, uid);
            }
          }
        });
    };

    const setInitialClientInfoIfEmpty = async (name, email, uid) => {
      const clientsRef = db.collection("clients");
      await clientsRef.doc(uid).set({
        name: name,
        email: email,
      });
    };
    console.log("Getting initial info for user: " + currentUid);
    getOrSetClientInfo(uid);
  }, [name, email, uid, currentUid]);

  React.useEffect(() => {
    const updateClientInfo = async (uid) => {
      const clientsRef = db.collection("clients");
      await clientsRef.doc(uid).set(clientInfo);
    };

    if (Object.keys(clientInfo).length > 0) {
      console.log("DATA UPDATED!");
      updateClientInfo(uid);
    }
    console.log("use effect");
  }, [uid, clientInfo]);

  return (
    <>
      {console.dir(clientInfo)}
      <GlobalCollection>
        <Hero>
          <div>
            <HeroWelcome>Welkom terug, {name}!</HeroWelcome>
            <p>
              Via deze tool kan je al je producten beheren die worden aangeboden
              door upshift
            </p>
          </div>
        </Hero>
        {/* <h2>Beta info</h2>
        <p>
          De gegevens hieronder kunnen gebruikt worden voor het oplossen van
          fouten in de beta fase van onze tool.
        </p>
        <h2>Sessie info</h2>
        <p>Uid: {uid}</p>
        <p>Naam: {name}.</p>
        <p>Email: {email}</p>
        <h2>URL info</h2>
        <p>{currentUid}</p>
        <h2>Klanten database</h2>
        {isAdmin && (
          <div>
            <p>Zoek gebruiker:</p>
            <input
              type="text"
              value={emailSearchString}
              onChange={(e) => handleUpdateEmailSearchString(e.target.value)}
            />
            <Link to={"/client/" + searchUid}>Link naar {searchUid}</Link>
          </div>
        )}
        <p>Naam: {clientInfo.name}</p>
        <p>Email: {clientInfo.email}</p>
        <p>
          Bedrijven: ({clientInfo.companies ? clientInfo.companies.length : "0"}
          )
        </p>
        {console.log(clientInfo.companies)}
        <ul>
          {clientInfo.companies && clientInfo.companies.length > 0 && (
            <>
              {clientInfo.companies.map((company, index) => (
                <li key={index}>
                  {company}
                  {isAdmin && (
                    <button onClick={() => handleDeleteCompany(index)}>
                      verwijderen
                    </button>
                  )}
                </li>
              ))}
            </>
          )}
          {isAdmin && (
            <li>
              <input
                id="newCompany"
                type="text"
                value={newCompany}
                onChange={(e) => handleNewCompany(e.target.value)}
              ></input>
              <button
                onClick={() => handleAddCompany()}
                onKeyPress={(e) => enterPressed(e.event)}
              >
                toevoegen
              </button>
            </li>
          )}
        </ul> */}
      </GlobalCollection>
    </>
  );
};

export default Client;

const HeroWelcome = styled.p`
  font-size: 2em;
  color: #1e3f5c;
  font-weight: 300;
  strong {
    font-weight: 600;
  }
`;
